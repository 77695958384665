// All sections
.section {
  position: relative;
  padding: 48px 0;

  &__title {
    color: $header-text-color;
    text-transform: uppercase;
    font-weight: 300;
    padding: 30px 0 0;
    margin: 0 0 36px;
    border: 0;
    border-top: 1px solid rgba(0,0,0,.12);
  }
  .h2 {
    font-size: 20px;
    line-height: 24px;
    font-weight: 400;
    color: rgba(0, 0, 0, 0.87);
  }

  &+.section {
    padding-top: 0;
  }
}

@media (max-width: 767px) {
  .section {
    &__title {
      font-size: 24px;
      margin: 0 0 24px;
    }
    .h2 {
      font-size: 16px;
      line-height: 24px;
      font-weight: 400;
      color: rgba(0, 0, 0, 0.87);
    }
  }
}