.footer {
  font-family: $footer-font-family;
  font-size: 14px;
  color: #424242;
  border-top: 1px solid #E0E0E0;
  background: #F5F5F5;
  padding: 32px 0 16px 0;

  p {
    margin-bottom: 16px;
    &:last-child {
      margin-bottom: 0;
    }
  }
}

.social-nav {
  margin-bottom: 11px !important; // magical number only for fotona site
  a {
    padding-left: 8px;
  }
}