.section--accessories {
  background: #fdfdfd;
}

.product-card {
  max-width: 250px;
  width: 100%;
  background: #ffffff;
  border-radius: 2px;
  box-shadow: 0 1px 3px 0 rgba(0,0,0,.12), 0 1px 2px 0 rgba(0,0,0,.24);

  padding: 16px;
  margin: 16px auto;

  &__img {
    width: 100%;
    vertical-align: middle;
    border-style: none;
  }

  &__body {
    display: flex;
    font-size: 14px;
    letter-spacing: .1px;
    color: rgba(0,0,0,.87);
    font-weight: 500;
    padding: 24px 0 0;
  }

  &__title {
    margin: 0;
  }

  &__link {
    margin: 0;
    margin-left: auto;
    color: $brand-main-color;
    &:hover {
      color: darken($brand-main-color, 10%);
    }
  }
}