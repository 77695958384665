.external-link {
  background: #f5f5f5;
  padding: 3rem 0;

  a {
    font-size: 1.5rem;
    line-height: 1;
    font-weight: 700;
    color: $brand-main-color;
    padding: .5rem 0 .5rem 3rem;
    display: inline-block;
    text-decoration: underline;
  }

  &--dark {
    background: $brand-main-color;
    a {
      color: #fafafa;
    }
  }
}
