.section--contacts {
  background: #fdfdfd;

  .form {
    background: #fff;
    border-radius: 2px;
    box-shadow: 0 1px 3px 0 rgba(0,0,0,.12), 0 1px 2px 0 rgba(0,0,0,.24);
    padding: 16px;
  }


}