// Typo
body {
  font-family: $main-font-family;
  //font-size: 16px;
  //font-weight: 400;
  font-size: 18px;
  line-height: 1.4;
  font-weight: 300;
  letter-spacing: 0.1px;
  color: $main-text-color;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-rendering: optimizeLegibility;
}
b,
strong,
.strong {
  font-weight: 400;
}
p {
  margin: 0 0 20px;
}
.lead {
  font-size: 20px;
  line-height: 28px;
  font-weight: 500;
  letter-spacing: 0.05px;
  color: rgba(0, 0, 0, 0.87);
}
h1,
.h1 {
  font-size: 28px;
  line-height: 40px;
  font-weight: 300;
  letter-spacing: 0;
  color: rgba(0, 0, 0, 0.54);
}
h2,
.h2 {
  font-size: 24px;
  line-height: 32px;
  font-weight: 300;
  letter-spacing: 0;
  color: rgba(0, 0, 0, 0.87);
}
h3,
.h3 {
  font-size: 20px;
  line-height: 24px;
  font-weight: 400;
  letter-spacing: 0.1px;
  color: rgba(0, 0, 0, 0.87);
}
h4,
.h4 {
  font-size: 14px;
  line-height: 24px;
  font-weight: 500;
  letter-spacing: 0.1px;
  color: rgba(0, 0, 0, 0.87);
}
h5,
.h5 {
  font-size: 14px;
  line-height: 24px;
  font-weight: 500;
  letter-spacing: 0.1px;
  color: rgba(0, 0, 0, 0.87);
}
h6,
.h6 {
  font-size: 14px;
  line-height: 20px;
  font-weight: 400;
  letter-spacing: 0.1px;
  color: rgba(0, 0, 0, 0.87);
}
h1 small,
h2 small,
h3 small,
h4 small,
h5 small,
h6 small,
.h1 small,
.h2 small,
.h3 small,
.h4 small,
.h5 small,
.h6 small,
h1 .small,
h2 .small,
h3 .small,
h4 .small,
h5 .small,
h6 .small,
.h1 .small,
.h2 .small,
.h3 .small,
.h4 .small,
.h5 .small,
.h6 .small {
  font-size: 50%;
  text-transform: uppercase;
}
h1,
.h1,
h2,
.h2 {
  font-weight: 400;
  margin: 40px 0 30px;
}
h3,
.h3,
h4,
.h4,
h5,
.h5,
h6,
.h6 {
  font-weight: 500;
  margin: 40px 0 20px;
}
.page-header {
  padding: 30px 0 0;
  margin: 40px 0;
  border: 0;
  border-top: 1px solid rgba(0, 0, 0, 0.12);
}
small,
.small {
  font-size: 12px;
  line-height: 20px;
  font-weight: 400;
  letter-spacing: 0.2px;
  color: rgba(0, 0, 0, 0.54);
}
mark,
.mark {
  background-color: #ffff8d;
  padding: 4px;
}
.text-left {
  text-align: left;
}
.text-right {
  text-align: right;
}
.text-center {
  text-align: center;
}
.text-justify {
  text-align: justify;
}
.text-nowrap {
  white-space: nowrap;
}
.text-lowercase {
  text-transform: lowercase;
}
.text-uppercase {
  text-transform: uppercase;
}
.text-capitalize {
  text-transform: capitalize;
}
ul,
ol {
  padding-left: 20px;
  margin-top: 0;
  margin-bottom: 20px;
}
ul ul,
ol ul,
ul ol,
ol ol {
  margin-bottom: 0;
}
.list-unstyled {
  padding-left: 0;
  list-style: none;
}
.list-inline {
  padding-left: 0;
  list-style: none;
}
.list-inline > li {
  display: inline-block;
  padding: 0 8px;
}
.list-inline > li:first-child {
  padding-left: 0;
}
.list-inline > li:last-child {
  padding-right: 0;
}
dl {
  margin-top: 0;
  margin-bottom: 40px;
}
dt,
dd {
  font-size: 14px;
  line-height: 20px;
  font-weight: 400;
  letter-spacing: 0.1px;
  color: rgba(0, 0, 0, 0.87);
}
dt {
  font-weight: 500;
}
dd {
  margin-left: 0;
  margin-bottom: 20px;
}
@media (min-width: 768px) {
  .dl-horizontal {
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: start;
    -webkit-align-items: flex-start;
    -ms-flex-align: start;
    align-items: flex-start;
  }
  .dl-horizontal dt {
    width: 180px;
    margin-right: 8px;
    text-align: right;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
  }
  .dl-horizontal dd {
    -webkit-box-flex: 1;
    -webkit-flex: 1;
    -ms-flex: 1;
    flex: 1;
  }
}
abbr[title],
abbr[data-original-title] {
  cursor: help;
  border-bottom: 1px dotted rgba(0, 0, 0, 0.12);
}
.initialism {
  font-size: 90%;
  text-transform: uppercase;
}
.blockquote {
  font-size: 20px;
  line-height: 28px;
  letter-spacing: 0.05px;
  color: rgba(0, 0, 0, 0.87);
  font-weight: 400;
  padding: 10px 20px;
  margin: 0 0 20px;
  border-left: 5px solid rgba(0, 0, 0, 0.26);
}
.blockquote p:last-child,
.blockquote ul:last-child,
.blockquote ol:last-child {
  margin-bottom: 0;
}
.blockquote footer {
  display: block;
  font-size: 12px;
  line-height: 20px;
  font-weight: 400;
  letter-spacing: 0.2px;
  color: rgba(0, 0, 0, 0.54);
}
.blockquote footer:before {
  content: '\2014 \00A0';
}
.blockquote-reverse {
  border-right: 5px solid rgba(0, 0, 0, 0.26);
  border-left: 0;
  text-align: right;
}
.blockquote-reverse footer:before {
  content: '';
}
.blockquote-reverse footer:after {
  content: '\00A0 \2014';
}
address {
  margin-bottom: 20px;
  font-style: normal;
}
hr {
  margin-top: 20px;
  margin-bottom: 20px;
  border: 0;
  border-top: 1px solid rgba(0, 0, 0, 0.12);
}
code,
kbd,
pre,
samp {
  font-family: 'Roboto Mono', Menlo, Monaco, Consolas, 'Courier New', monospace;
}
code {
  padding: 2px 4px;
  font-size: 90%;
  color: #616161;
  background-color: rgba(0, 0, 0, 0.12);
  border-radius: 2px;
}
pre {
  display: block;
  padding: 8px;
  margin: 0 0 8px;
  font-size: 12px;
  line-height: 20px;
  color: rgba(0, 0, 0, 0.87);
  word-break: break-all;
  word-wrap: break-word;
  background-color: #f5f5f5;
  border: 1px solid #eeeeee;
  border-radius: 2px;
}
.text-muted {
  color: rgba(0, 0, 0, 0.26);
}
.text-primary {
  color: #2196f3;
}
a.text-primary:hover,
a.text-primary:focus {
  color: #1e88e5;
}
.text-success {
  color: #4caf50;
}
a.text-success:hover,
a.text-success:focus {
  color: #43a047;
}
.text-info {
  color: #03a9f4;
}
a.text-info:hover,
a.text-info:focus {
  color: #039be5;
}
.text-warning {
  color: #ff9800;
}
a.text-warning:hover,
a.text-warning:focus {
  color: #fb8c00;
}
.text-danger {
  color: #f44336;
}
a.text-danger:hover,
a.text-danger:focus {
  color: #e53935;
}
.bg-primary {
  background-color: #2196f3;
}
a.bg-primary:hover,
a.bg-primary:focus {
  background-color: #1e88e5;
}
.bg-success {
  background-color: #4caf50;
}
a.bg-success:hover,
a.bg-success:focus {
  background-color: #43a047;
}
.bg-info {
  background-color: #03a9f4;
}
a.bg-info:hover,
a.bg-info:focus {
  background-color: #039be5;
}
.bg-warning {
  background-color: #ff9800;
}
a.bg-warning:hover,
a.bg-warning:focus {
  background-color: #fb8c00;
}
.bg-danger {
  background-color: #f44336;
}
a.bg-danger:hover,
a.bg-danger:focus {
  background-color: #e53935;
}
.zmdt-display-4,
.zmdt-display-4-inverse {
  font-size: 112px;
  line-height: 128px;
  font-weight: 300;
  letter-spacing: -0.1px;
}
.zmdt-display-4 {
  color: rgba(0, 0, 0, 0.54);
}
.zmdt-display-4-inverse {
  color: rgba(255, 255, 255, 0.7);
}
.zmdt-display-3,
.zmdt-display-3-inverse {
  font-size: 56px;
  line-height: 84px;
  font-weight: 400;
  letter-spacing: -0.05px;
}
.zmdt-display-3 {
  color: rgba(0, 0, 0, 0.54);
}
.zmdt-display-3-inverse {
  color: rgba(255, 255, 255, 0.7);
}
.zmdt-display-2,
.zmdt-display-2-inverse {
  font-size: 45px;
  line-height: 48px;
  font-weight: 400;
  letter-spacing: 0;
}
.zmdt-display-2 {
  color: rgba(0, 0, 0, 0.54);
}
.zmdt-display-2-inverse {
  color: rgba(255, 255, 255, 0.7);
}
.zmdt-display-1,
.zmdt-display-1-inverse {
  font-size: 34px;
  line-height: 40px;
  font-weight: 400;
  letter-spacing: 0;
}
.zmdt-display-1 {
  color: rgba(0, 0, 0, 0.54);
}
.zmdt-display-1-inverse {
  color: rgba(255, 255, 255, 0.7);
}
.zmdt-headline,
.zmdt-headline-inverse {
  font-size: 24px;
  line-height: 32px;
  font-weight: 400;
  letter-spacing: 0;
}
.zmdt-headline {
  color: rgba(0, 0, 0, 0.87);
}
.zmdt-headline-inverse {
  color: #ffffff;
}
.zmdt-title,
.zmdt-title-inverse {
  font-size: 20px;
  line-height: 28px;
  font-weight: 500;
  letter-spacing: 0.05px;
}
.zmdt-title {
  color: rgba(0, 0, 0, 0.87);
}
.zmdt-title-inverse {
  color: #ffffff;
}
.zmdt-subhead-2,
.zmdt-subhead-2-inverse {
  font-size: 16px;
  line-height: 28px;
  font-weight: 400;
  letter-spacing: 0.1px;
}
.zmdt-subhead-2 {
  color: rgba(0, 0, 0, 0.87);
}
.zmdt-subhead-2-inverse {
  color: #ffffff;
}
.zmdt-subhead-1,
.zmdt-subhead-1-inverse {
  font-size: 16px;
  line-height: 24px;
  font-weight: 400;
  letter-spacing: 0.1px;
}
.zmdt-subhead-1 {
  color: rgba(0, 0, 0, 0.87);
}
.zmdt-subhead-1-inverse {
  color: #ffffff;
}
.zmdt-body-2,
.zmdt-body-2-inverse {
  font-size: 14px;
  line-height: 24px;
  font-weight: 500;
  letter-spacing: 0.1px;
}
.zmdt-body-2 {
  color: rgba(0, 0, 0, 0.87);
}
.zmdt-body-2-inverse {
  color: #ffffff;
}
.zmdt-body-1,
.zmdt-body-1-inverse {
  font-size: 14px;
  line-height: 20px;
  font-weight: 400;
  letter-spacing: 0.1px;
}
.zmdt-body-1 {
  color: rgba(0, 0, 0, 0.87);
}
.zmdt-body-1-inverse {
  color: #ffffff;
}
.zmdt-menu,
.zmdt-menu-inverse {
  font-size: 13px;
  line-height: 20px;
  font-weight: 400;
  letter-spacing: 0.1px;
}
.zmdt-menu {
  color: rgba(0, 0, 0, 0.87);
}
.zmdt-menu-inverse {
  color: #ffffff;
}
.zmdt-caption,
.zmdt-caption-inverse {
  font-size: 12px;
  line-height: 20px;
  font-weight: 400;
  letter-spacing: 0.2px;
}
.zmdt-caption {
  color: rgba(0, 0, 0, 0.54);
}
.zmdt-caption-inverse {
  color: rgba(255, 255, 255, 0.7);
}
.zmdt-button,
.zmdt-button-inverse {
  font-size: 14px;
  line-height: 20px;
  font-weight: 500;
  letter-spacing: 0.1px;
}
.zmdt-button {
  color: rgba(0, 0, 0, 0.87);
}
.zmdt-button-inverse {
  color: #ffffff;
}
.zmdt-button,
.zmdt-button-inverse {
  text-transform: uppercase;
}
.zmdt-display-4,
.zmdt-display-3,
.zmdt-display-2,
.zmdt-display-1,
.zmdt-display-4-inverse,
.zmdt-display-3-inverse,
.zmdt-display-2-inverse,
.zmdt-display-1-inverse {
  margin: 24px 0 32px;
}
.zmdt-headline,
.zmdt-title,
.zmdt-subhead-2,
.zmdt-subhead-1,
.zmdt-body-2,
.zmdt-body-1,
.zmdt-headline-inverse,
.zmdt-title-inverse,
.zmdt-subhead-2-inverse,
.zmdt-subhead-1-inverse,
.zmdt-body-2-inverse,
.zmdt-body-1-inverse {
  margin: 24px 0;
}
