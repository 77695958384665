// Header
.header {
  padding: 220px 0 160px; // menu height
  min-height: 762px;

  position: relative;
  background-position: 50%;
  background-size: cover;

  &__card {
    background: rgba(255, 255, 255, .9);
    padding: 48px 32px;
  }

  h1 {
    font-size: 36px;
    color: $header-text-color;
    text-transform: uppercase;
  }

  .lead {
    padding-top: 36px;
  }
}

@media (max-width: 767px) {
  #header {
    //background-color: $main-color;
    //background-image: url("../../images/backgrounds/header.jpg");
  }
  .header {
    h1 {
      font-size: 20px;
    }
    .lead {
      padding-top: 16px;
      font-size: 16px;
    }
    .btn+.btn {
      margin-top: 16px;
    }
  }
}