table.table--fotona {
  thead {
    tr {
      background: $brand-main-color;
      color: #ffffff;
    }
    th {
      border: 0;
      font-weight: 700;
    }
  }

  td, th {
    font-size: 16px;
    font-weight: 400;
  }
  td {
    font-weight: 300;
  }

  .subtitle {
    color: $brand-main-color;
    font-weight: 700;
  }
}
