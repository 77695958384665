.modal--research {

  .modal-title {
    font-size: 18px;
  }

  h3 {
    font-size: 20px;
    font-weight: 400;
    line-height: 1.1;
    color: $brand-main-color;
    margin: 32px 0 16px;

    &:first-child {
      margin-top: 0;
    }
  }

  p {
    font-size: 14px;
  }

  a {
    font-weight: 400;
    color: $brand-main-color;
  }
}