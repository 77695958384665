.video {

  &__block {
    background-size: cover;
    background-position: center;
    padding: 48px 16px 98px;
  }

  &__nav-container {
    position: relative;
  }

  &__content {
    position: relative;
    background: rgba(255, 255, 255, .9);
    padding: 48px 32px;

    &:hover {
      cursor: pointer;
    }

    display: block;
    text-decoration: none !important;
    color: $main-text-color;
    &:hover {
      color: $main-text-color;
    }
  }

  &__play-btn {
    position: absolute;
    left: 16px;
    top: 16px;
  }

  &__title {
    color: #37474f;
    font-size: 28px;
    line-height: 48px;
    text-transform: uppercase;
    font-weight: 300;
    padding: 32px 0 0;
    margin: 0 0 24px;
    text-align: center;
  }

  &__description {
    font-size: 20px;
    line-height: 28px;
    font-weight: 400;
    text-align: center;
  }

  &__nav {
    width: 100%;
    position: absolute;
    bottom: 24px;
    left: 0;
    right: 0;

    border-bottom: 0;
    justify-content: center;

    a {
      margin: 0 16px;
    }
  }
}