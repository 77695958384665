.owl-carousel--awards {
  .award {
    text-align: center;
    font-weight: 400;
    font-size: 14px;
    line-height: 20px;
    text-transform: uppercase;
    color: $header-text-color;
  }

  .owl-item img {
    display: inline-block;
    width: 150px;
    height: 150px;
  }

  .owl-nav [class*=owl-] {
    top: 55px;
  }
}
