.btn-light {
  @include button-variant(
      rgba(255, 255, 255, .7),
      transparent,
      rgba(255, 255, 255, .9),
      $brand-main-color,
      rgba(255, 255, 255, .9),
      $brand-main-color
  );
  border-radius: 0;
  padding: .5rem 1.5rem;
  margin: 0 .5rem;
  min-width: 250px;
  border: 0;
  border-bottom: 2px solid transparent;
}

.btn-brand {
  @include button-variant(
      $brand-main-color,
      transparent
  );
  border: 0;
}
