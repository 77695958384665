.accordion {

  margin-bottom: 1rem;
  background-color: #fff;
  border: none;
  border-radius: 2px;
  box-shadow: 0 1px 3px 0 rgba(0,0,0,.12), 0 1px 2px 0 rgba(0,0,0,.24);

  &__header {
    padding: 1rem 2rem;
    border-radius: 2px 2px 0 0;
    line-height: 1;
    width: 100%;
    color: $header-text-color;
    background-color: #f5f5f5;
    border-color: transparent;
    font-weight: 500;
    cursor: pointer;
    transition: all .3s ease;

    &.collapsed {
      background-color: #fafafa;
      &:hover {
        background-color: #f5f5f5;
      }

      .accordion__title--arrow::after {
        transform: rotate(0);
      }
    }
  }

  &__title {
    position: relative;
    margin: 0;
    padding: 0;
    font-size: 1rem;
    line-height: 25px;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;

    &--arrow {
      padding-right: 25px;
      &::after {
        content: "";
        position: absolute;
        top: 0;
        right: 0;
        width: 25px;
        height: 100%;
        transition: all .3s ease;
        transform: rotate(180deg);
        background: url(../img/accordion/arrow.svg) no-repeat right center;
        background-size: contain;
      }
    }
  }

  &__body {
    padding: 2rem;
  }
}

@media (min-width: 992px) {
  .section--features {
    .container {
      background-image: url(../img/features-background.jpg);
      background-size: 400px;
      background-repeat: no-repeat;
      background-position: right 100px;
    }
  }
  .accordion {
    width: 75%;
  }
}