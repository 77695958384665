$treatment-block-height: 175px;
$treatment-title-height: 35px;
$treatment-img-height: $treatment-block-height - $treatment-title-height;

.treatments {
  position: relative;
  margin-top: 48px;
  margin-bottom: 24px;

  &__bg-line {
    position: absolute;
    height: 150px;
    width: 100%;
    background: $brand-main-color;
  }

  &__container {
    @extend .container;
    position: relative;
    display: flex;
    flex-wrap: wrap;

  }

  &__block {
    display: block;
    padding: 0;
    margin: $treatment-title-height auto;
    height: $treatment-block-height;
    background: $brand-main-color;
    border-radius: 2px;
    box-shadow: 0 1px 3px 0 rgba(0,0,0,.12), 0 1px 2px 0 rgba(0,0,0,.24);
    transition: all .15s ease;

    &:hover {
      background: darken($brand-main-color, 10%);
      box-shadow: 0 3px 6px rgba(0, 0, 0, .16), 0 3px 6px rgba(0, 0, 0, .23);
      text-decoration: none;
    }
  }

  &__img {
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;
    height: $treatment-img-height;
  }

  &__title {
    font-size: 12px;
    font-weight: 400;
    line-height: $treatment-title-height;
    text-transform: uppercase;
    text-align: center;
    color: #ffffff;
    padding: 0 16px;

    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
  }

  ~.treatments {
    margin-top: 32px;
  }
}
