/* open-sans-condensed-300 - cyrillic */
@font-face {
  font-family: 'Open Sans Condensed';
  font-style: normal;
  font-weight: 300;
  src: url('../fonts/open-sans-condensed/open-sans-condensed-v12-cyrillic-300.eot'); /* IE9 Compat Modes */
  src: local('Open Sans Condensed Light'), local('OpenSansCondensed-Light'),
  url('../fonts/open-sans-condensed/open-sans-condensed-v12-cyrillic-300.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
  url('../fonts/open-sans-condensed/open-sans-condensed-v12-cyrillic-300.woff2') format('woff2'), /* Super Modern Browsers */
  url('../fonts/open-sans-condensed/open-sans-condensed-v12-cyrillic-300.woff') format('woff'), /* Modern Browsers */
  url('../fonts/open-sans-condensed/open-sans-condensed-v12-cyrillic-300.ttf') format('truetype'), /* Safari, Android, iOS */
  url('../fonts/open-sans-condensed/open-sans-condensed-v12-cyrillic-300.svg#OpenSansCondensed') format('svg'); /* Legacy iOS */
}
