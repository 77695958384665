.advantages {

  margin: 24px 0;

  &__block {
    display: flex;
    margin: 16px auto;
  }

  &__img {
    flex: 0 0 auto;
    width: 100px;
    height: 100px;
    border: 1px dashed #7b7b7b;
    background: white;
    //margin: 0 auto;
    border-radius: 50%;
    text-align: center;
    line-height: 100px;

    img {
      width: 50px;
      height: 50px;
    }
  }

  &__title {
    align-self: center;
    font-weight: 400;
    padding-left: 36px;
  }
}
