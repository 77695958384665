// Typography
$main-font-family: 'LatoWeb', 'Roboto', 'Helvetica Neue', Arial,  sans-serif;
$header-font-family: 'LatoWeb', 'Roboto', 'Helvetica Neue', Arial,  sans-serif;
$footer-font-family: 'LatoWeb', 'Roboto', 'Helvetica Neue', Arial,  sans-serif;

$main-text-color: #212121;
$header-text-color: #37474F;

// Brand
$brand-main-color: #ed1c24;
