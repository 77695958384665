// Override bootstrap styles
.navbar {
  font-family: "Open Sans Condensed", sans-serif;
  font-size: 20px;
  font-weight: 300;
  letter-spacing: 0;
}
.navbar-collapse {
  flex-grow: 0;
}

// Fotona style
.navbar--fotona {
  background: #ffffff;
  border-bottom: 2px solid $brand-main-color;

  .navbar-nav .active>.nav-link,
  .navbar-nav .nav-link.active,
  .navbar-nav .nav-link.show,
  .navbar-nav .show>.nav-link {
    color: $brand-main-color;
  }

  .navbar-nav .nav-link {
    color: #616161;
  }
  .navbar-nav .nav-link:focus,
  .navbar-nav .nav-link:hover {
    color: #212121;
  }
}

@media (min-width: 992px) {
  .navbar--fotona.navbar-expand-lg .navbar-nav .nav-link {
    padding-right: 1rem;
    padding-left: 1rem;
  }
}
